import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { client, urlFor } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Skills.scss';

const Skills = () => {
    // const [experiences, setExperiences] = useState([]);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        // const query = '*[_type == "experiences"]';
        const skillsQuery = '*[_type == "otherskills"]';

        // client.fetch(query).then((data) => {
        //     setExperiences(data);
        // });

        client.fetch(skillsQuery).then((data) => {
            setSkills(data);
        });
    }, []);

    return (
        <>
            <h2 className="head-text">Skills & Experiences</h2>

            <div className=" w-fit">
                <motion.div className="w-fit flex items-center gap-4">
                    {skills.map((skill) => (
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                            className="app__skills-item app__flex"
                            key={skill.skillName}
                        >
                            <div
                                className="app__flex"
                               
                            >
                                <img src={urlFor(skill.skillImage).width(150)} alt={skill.skillName} />
                            </div>
                            <p className="p-text">{skill.skillName}</p>
                        </motion.div>
                    ))}
                </motion.div>
                
            </div>
        </>
    );
};

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__whitebg',
);