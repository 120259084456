import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './More.scss';
import { urlFor, client } from '../../client';
import BlockContent from '@sanity/block-content-to-react';

const More = () => {
  const [more, setMore] = useState();
  const [single, setSingle] = useState(false)

  useEffect(() => {
    const query = `
    *[_type == "more"]{
      name,
      summary,
      imgURL,
      description
    }
    `;

    client.fetch(query).then((data) => {
      console.log(data)
      setMore(data);
    });
  }, []);

  if(!more) return <p>Loading...</p>

  return (
    <>
      {
        (single !== false)
        ?
        <motion.div
        transition={{ duration: 0.5 }}
        initial={{x: 100}}
        animate={{x: 0}}
        exit={{x: 100}}
        className="relative flex items-start gap-3 flex-wrap"
        >
          <button onClick={()=>setSingle(false)} className="absolute top-0 left-0">back</button>
          <img src={urlFor(more[single].imgURL).width(400)} alt="more pic" className='object-cover h-[25rem] w-[25rem]'/>
          <BlockContent blocks={more[single].description} projectId={process.env.REACT_APP_SANITY_PROJECT_ID} dataset="production" className={"prose leading-5"}/>
        </motion.div>
        :
        <motion.div 
        transition={{ duration: 0.5 }}
        initial={{x: -100}}
        animate={{x: 0}}
        exit={{x: -100}}>
          <h2 className="head-text more-heading "> <span>Organizations </span> I've worked with <br /></h2>

          <div className={`app__profiles`}>
            {more.map((more, index) => (
              <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className="app__profile-item"
                key={more.name + index}
                onClick={()=>{
                  console.log("setting single to ", index)
                  setSingle(index)
                }}
              >
                <img src={urlFor(more.imgURL).width(400)} alt={more.name} className="object-cover"/>
                <h2 className="bold-text" style={{ marginTop: 20 }}>{more.name}</h2>
                <p className="p-text" style={{ marginTop: 10 }}>{more.summary}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      }
    </>
  );
};

export default AppWrap(
  MotionWrap(More, 'app__about'),
  'more',
  'app__primarybg',
);