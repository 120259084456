import React from 'react';
// import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
// import { urlFor, client } from '../../client';
import BlockContent from '@sanity/block-content-to-react';
import { useEffect, useState } from 'react';
import { client } from '../../client';
import images from '../../constants/images';

const About = () => {
    const [metadata, setMetadata] = useState(null)
    useEffect(() => {
        let query = `
        *[_type == "metadata"]{
            description,
            name,
            profile
          }
        `
        client.fetch(query).then(response => {
            console.log(response)
            setMetadata(response[0])
        }).catch(console.error)
    }, [])
    if (!metadata) return <p>Loading...</p>
    return (
        <div id='about' className='w-fullmin-h-fit h-fit md:h-screen p-2 flex items-center py-16'>
            <div className='max-w-[1240px] m-auto md:grid grid-cols-3 gap-8'>
                <div className='col-span-2'>
                    <p className='uppercase text-xl tracking-widest text-[#3c8ffc] app-parafrontendf'>
                        About
                    </p>
                    <h2 className='py-4 app-head'>Who I Am</h2>
                    <p className='py-2 text-[#3c8ffc]'>
                        Hi I'am {metadata.name}
                    </p>
                    <p className='py-2 text-gray-600 text-sm'>
                        <BlockContent blocks={metadata.description} projectId={process.env.REACT_APP_SANITY_PROJECT_ID} dataset="production" className='prose leading-5' />

                    </p>
                    {/* <p className='py-2 text-gray-600'>
                        These are some qualities that I think sets me apart from other candidates

                    </p>
                    <p className='py-2 text-gray-600 flex text-sm'>
                        <IoMdArrowDropright />  One of the things that distinguish me from other candidates is that I am able to remain calm and focused no matter how difficult the task. I always try to stay positive and find a way out. I am sure that everything happens for a reason and that any mistake can be fixed.
                    </p>
                    <p className='py-2 text-gray-600 flex text-sm'>
                        <IoMdArrowDropright />  I am a quick learner and constantly improve my skills not only in professional but also in multiple other fields. If hired for the position, I will continue to learn and transfer obtained knowledge and skills to other team members.

                    </p>
                    <p className='py-2 text-gray-600 flex text-sm'>
                        <IoMdArrowDropright />  You will never find a more disciplined person. I am never late and know how to value the time of other people. Especially in the engineering field, where everything should be clear, structured, and meet requirements.
                    </p>
                    <a href='/#work'>
                        <button className='flex justify-center items-center my-2'>Check out my projects <BsArrowRightShort /></button>
                    </a> */}
                </div>
                <div className='w-full h-auto m-auto shadow-xl shadow-gray-400 rounded-xl flex items-center justify-center p-4 hover:scale-105 ease-in duration-300'>
                    <img src={images.profile2} className='rounded-xl' alt='/' />
                </div>
            </div>
        </div >
    );
};
export default AppWrap(
    MotionWrap(About, 'app__about'),
    'about',
    'app__whitebg')