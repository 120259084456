import React from 'react';

// import { AppWrap } from '../../wrapper';
import { useEffect, useState } from 'react';
import { MotionWrap } from '../../wrapper';
import './Header.scss';

import { client, urlFor } from '../../client';


const Header = () => {
    const [socialmedia, setSocialmedia] = useState(null)
    useEffect(() => {
        let query = `
            *[_type == "metadata"]{
                socialmedia
            }[0]
        `
        client.fetch(query).then(response => {
            setSocialmedia(response.socialmedia)
        }).catch(console.error)
    }, [])
    return (
        <div id='home' className='w-full h-screen text-center'>
            <div className='max-w-[1240px] w-full h-full mx-auto p-2 flex justify-center items-center'>
                <div>
                    {/* <p className='uppercase text-sm tracking-widest text-gray-600 app-p'>
                        LET&#39;S BUILD SOMETHING TOGETHER
                    </p> */}
                    <h1 className='py-4 text-gray-700 app-head'>
                        Hi, I&#39;m <span className='text-[#3c8ffc]'> Daniel Tabaro</span>
                    </h1>
                    <h1 className='py-2 text-gray-700 app-head'>A professional medical doctor</h1>
                    <p className='py-4 text-gray-600 sm:max-w-[70%] m-auto app-p'>While primarily dedicated to providing attentive medical care to patients, I am also keen on exploring and learning about advancements in other medical technologies and specialties.
                    </p>
                    <div className='flex items-center justify-between max-w-[330px] w-fit m-auto py-4'>
                        {
                            socialmedia && socialmedia.map((x, i) => {
                                return (
                                    <a href={x.sociallink} target='_blank' rel="noreferrer">
                                        <div className='rounded-full shadow-lg shadow-gray-400 p-1 cursor-pointer hover:scale-110 ease-in duration-300'>
                                            <img src={urlFor(x.socialicon).width(30)} alt={x.socialname} className="h-7 w-7 object-cover" />
                                        </div>
                                    </a>
                                )
                            })
                        }
                        {/* <a
                            href='https://github.com/Hansraj8149?tab=repositories'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                                <FaGithub />
                            </div>
                        </a>
                        <a href='/#contact'>
                            <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                                <AiOutlineMail />
                            </div>
                        </a>
                        <a href={Resume}>
                            <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                                <BsFillPersonLinesFill />
                            </div>
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

// export default AppWrap(Header, 'home');
export default MotionWrap(Header, 'home')