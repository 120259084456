import './App.scss';
import { Navbar } from './components/index';
import { About, Footer, Header, More, Skills } from './container/index';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      {/* <Work /> */}
      <About />
      <More />
      <Skills />
      <Footer />

    </div>
  );
}

export default App;
