import React, { useEffect, useState } from 'react'

import { client, urlFor } from '../client'

const SocialMedia = () => {
    const [socialmedia, setSocialmedia] = useState(null)
    useEffect(()=>{
        let query = `
            *[_type == "metadata"]{
                socialmedia
            }[0]
        `
        client.fetch(query).then(response => {
            setSocialmedia(response.socialmedia)
        }).catch(console.error)
    }, [])
    if(!socialmedia) return <span>...</span>
    return (
        <div className='app__social'>
            {
                socialmedia.map((x, i) => {
                    return (
                        <div>
                            <a href={x.sociallink}>
                                <img src={urlFor(x.socialicon).width(30)} alt={x.socialname} />
                            </a>
                        </div>
                    )
                })
            }
            {/* <div>
                <a href="https://www.instagram.com/direct/inbox/">
                    <BsInstagram />
                </a>
            </div>
            <div>
                <a href="https://github.com/Hansraj8149?tab=repositories">
                    <FaGithub />
                </a>
            </div> */}

        </div>
    )
}

export default SocialMedia